<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      width="500"
    >
      <v-card primary>
        <v-card-title class="headline primary" >
          ログイン
        </v-card-title>
        <p></p>
        <v-card-text>あなたのメールアドレスを入力してログインボタンをクリックしてください。</v-card-text>
        <v-form v-model="valid" @submit.prevent>
          <v-container class="d-flex flex-row">
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                autocomplete="email"
              ></v-text-field>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="ml-8"
              @click="login"
            >
              ログイン
            </v-btn>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-overlay :z-index="10000" :value="!dialog && !errorDialog">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
let onAuthStateChangedUnmount = null;

export default {
  data() {
    return {
      dialog: false,
      errorDialog: false,
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mailの入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'E-mailの形式が正しくありません。',
      ],
    }
  },
  methods: {
    async login() {
      // ログインフォームの表示
      try {
        this.email = this.email.toLowerCase();  // メールの小文字化
        const result = await this.$root.$progressive(this.$functions.verifyEmail({ email: this.email }));
        window.localStorage.setItem('emailForSignIn', this.email); // 検証用メールアドレスの登録
        await this.$root.$alert_no_button(
          `${this.email} にメールをお送りしました。`,
          [
            `${this.email} 宛に認証メール（件名: ${result.data}）を送信します。`,
            'ご登録のないメールアドレスにはお届けできません。',
            `数分以内にメールが届かない場合、メールアドレス ${this.email} をお確かめのうえ再度お試しください。`,
            'メールアドレスを自動入力する場合は、Chromeのオートコンプリート機能を設定してください。',
            'URL：chrome://settings/addresses',
          ],
          { width: '720px' }
        );
      } catch (e) {
        console.error(e);
      }
    }
  },
  async mounted() {
    const email = window.localStorage.getItem('emailForSignIn');
    console.log('login mounted', email);

    // ユーザーの状態を監視
    onAuthStateChangedUnmount = this.$auth.onAuthStateChanged(async (user) => {

      // ログイン済みの場合は何もしない
      if (user) return;

      // メールリンクからでない場合はログインダイアログを表示して終了
      if (!this.$auth.isSignInWithEmailLink(window.location.href)) {
        this.dialog =  true;
        return;
      }

      // 以下はメールリンクからの場合
      try {

        // メールアドレスが保存されていない場合はエラー
        if ( !email ) {
          throw new Error([
            `ログイン操作が行われていない可能性があるためログインできません。`,
            '再度ログインをお試しください。',
          ]);
        }

        // メールリンクでログイン
        this.$store.commit('afterLogin');
        let result = await this.$auth.signInWithEmailLink(email, window.location.href);
        if (result) {
          window.localStorage.removeItem('emailForSignIn');
          window.localStorage.removeItem('pendingPath');
          this.$functions.log({
            tags: ['auth', 'login'],
            message: `ログインしました。`,
          });
          // ログイン成功（URLからAPIキーを削除するため再接続する）
          window.location.href = '/#/login';
        }

      } catch (e) {

        console.error(e);
        let errMsgs= [];
        if (e.code == 'auth/invalid-action-code') {
          // 古いリンクをクリックされた場合など
          errMsgs = [
            `クリックされたリンクは最新でない可能性があるためログインできません。`,
            '最新のリンクをクリックしてログインをお試しください。',
          ];
        } else if (e.code) {
          // その他の認証エラーの場合
          errMsgs = [e.code];
        } else {
          // 認証エラー以外
          errMsgs = e.message.split(',');
        }
        // エラーダイアログ表示
        this.errorDialog = true;
        await this.$root.$alert_multi_line(
          `ログインできませんでした。`,
          errMsgs,
          { width: '720px' }
        );
        // APIキー付きのURLの場合は更新ボタンでまたエラー画面が表示されるので
        // 再接続することでAPIキーを削除する
        window.location.href = '/#/login';

      }
    });
  },
  beforeDestroy() {
    if (onAuthStateChangedUnmount) onAuthStateChangedUnmount();
  },
  computed: {
  }
};
</script>

<style scoped>

.primary {
  color: white;
}
</style>
